/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  ChangeEvent, useContext, useState,
} from 'react';
import {
  Button, Snackbar,
} from '@mui/material/';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import OliAxios from 'api/util/OliAxios';
import UserContext from 'context/UserContext';
import { getConfig } from 'api/util/getConfig';
import { useStyles } from './FileUploadButton.jss';

type PreSignedURLResponse = {
  data: PreSignedData
};

type PreSignedData = {
  uploadURL: string
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const FileUploadButton: React.FunctionComponent = () => {
  const classes = useStyles;
  const API_ENDPOINT = '/files/upload';
  const token = useContext(UserContext)?.token;
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);

  async function handleFileChange(event: ChangeEvent<HTMLInputElement>, retryCount = 2):Promise<void> {
    if (!token) return;
    let retry = retryCount;
    const { files } = event.target;
    if (!files) return;
    try {
      const response:PreSignedURLResponse = await OliAxios.get(API_ENDPOINT, {
        ...getConfig(token), params: { filename: files[0].name },
      });
      const options = {
        headers: {
          'Content-Type': files[0].type,
        },
      };
      await OliAxios.put(response.data.uploadURL, files[0], options);
    } catch (e) {
      if (retry < 2) {
        handleFileChange(event, retry += 1);
      } else {
        throw e as Error;
      }
    }
  }

  const handleSuccess = (e:ChangeEvent<HTMLInputElement>):void => {
    setLoading(false);
    setOpenSuccess(true);
    e.target.value = '';
  };

  const handleError = (er:Error, e:ChangeEvent<HTMLInputElement>):void => {
    setLoading(false);
    setOpenFail(true);
    setError(er);
    e.target.value = '';
  };

  const onFileChange = (e:ChangeEvent<HTMLInputElement>):void => {
    setLoading(true);
    handleFileChange(e)
      .then(() => { handleSuccess(e); })
      .catch((er) => handleError(er, e));
  };

  const closeSnackBar = ():void => {
    setOpenSuccess(false);
    setOpenFail(false);
  };

  return (
    <span>
      <input
        accept="'.nl', '.js', '.fl', '.txt', '.upd', '.rmt', '.upl', '.cst'"
        id="file"
        type="file"
        onChange={onFileChange}
        hidden
      />
      <label htmlFor="file">
        <Button disabled={loading} color="secondary" variant="contained" component="span" sx={classes.uploadButton}>
          Upload
        </Button>
      </label>
      <Snackbar
        open={openSuccess}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity="success" sx={classes.alert}>
          File Upload Complete.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFail}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity="error" sx={classes.alert}>
          Something has gone wrong. (
          {error?.message}
          )
        </Alert>
      </Snackbar>
    </span>
  );
};

export default FileUploadButton;
