import React from 'react';
import {
  createTheme, ThemeProvider,
  AppBar, Toolbar, Typography,
  Button, Menu, MenuItem,
} from '@mui/material';
import UserContext from 'context/UserContext';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IdleTimerModal from 'components/IdleTimerModal/IdleTimerModal';
import Home from 'components/Home';
import Logo from 'components/Logo/Logo';
import Superset from 'components/Superset/Superset';
import useUser from 'hooks/useUser.hook';
import {
  BrowserRouter, Routes, Route, Link,
} from 'react-router-dom';
import FileTransfer from 'components/FileTransfer/FileTransfer';
import FileTransferDashboard from 'components/FileTransfer/Dashboard/Dashboard';
import MatterHome from 'components/Matter/MatterHome/MatterHome';
// import MissingDocDashboard from 'components/FileTransfer/Dashboard/MissingDocDashboard/MissingDocDashboard';
import QuickSearch from 'components/Search/QuickSearch';
import SearchResults from 'components/Search/SearchResults/SearchResults';
import UserDashboard from 'components/UserDashboard/UserDashboard';
import SupersetMenu from 'components/Superset/SupersetMenu';
import Docusign from 'components/Docusign/Docusign';
import { useStyles } from './Adt.jss';
import { AdtProps } from './Adt.types';

const Adt: React.FunctionComponent<AdtProps> = (props) => {
  const [anchorEl, setAnchorElFileTransfer] = React.useState<null | HTMLElement>(null);
  const [anchorElInsights, setAnchorElInsights] = React.useState<null | HTMLElement>(null);
  const [report, setReport] = React.useState('operations_daily_audit');
  const handleClickFT = (event:React.MouseEvent<HTMLElement>):void => {
    setAnchorElFileTransfer(event.currentTarget);
  };
  const handleClickInsights = (event:React.MouseEvent<HTMLElement>):void => {
    setAnchorElInsights(event.currentTarget);
  };
  const handleClose = ():void => {
    setAnchorElFileTransfer(null);
  };

  const {
    user, signOut,
  } = props;

  const styles = useStyles;

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3e3842', // slate
      },
      secondary: {
        main: '#ca304c', // red
      },
    },
  });

  const {
    token, type, name, supersetUser, userName,
  } = useUser(user);

  let showInsights = false;

  if (type === 'CREDITOR' && process.env.REACT_APP_SUPERSET_URL != null
       && process.env.REACT_APP_SUPERSET_URL.length > 4) {
    showInsights = true;
  }

  const oliverSignOut = ():void => {
    signOut(); //eslint-disable-line
  };

  const mailSupport = ():void => {
    window.open('https://olivertechnology.atlassian.net/servicedesk/customer/portals');
  };

  const renderInsightsLink = ():JSX.Element => {
    if (showInsights) {
      return <Button sx={styles.button} onClick={handleClickInsights}>Insights</Button>;
    }
    return <></>;
  };

  const renderQuickSearch = ():JSX.Element => {
    if (type && type === 'CREDITOR') {
      return <QuickSearch />;
    }
    return <></>;
  };

  const renderInsightsRoute = ():JSX.Element => {
    if (showInsights) {
      return <Route path="insights" element={<Superset report={report} />} />;
    }
    return <></>;
  };

  const renderHomeRoute = ():JSX.Element => {
    if (type && type !== 'CREDITOR') {
      return <Route path="/" element={<Home />} />;
    }
    if (type && type === 'CREDITOR') {
      return <Route path="/" element={<UserDashboard />} />;
    }
    return <></>;
  };

  const renderADTLink = ():JSX.Element => {
    if (type && type === 'CREDITOR') {
      return <Link style={styles.link} to="/adt"><Button sx={styles.button}>ADT</Button></Link>;
    }
    return <></>;
  };

  const renderToolBar = ():JSX.Element => {
    if (window.location.pathname !== '/docusignClose') {
      return (
        <AppBar position="sticky" sx={styles.appBar}>
          <Toolbar>
            <Logo router />
            {renderQuickSearch()}
            <Typography variant="h6" sx={styles.title} />
            <Link style={styles.link} to="/"><Button sx={styles.button}>Home</Button></Link>
            {renderInsightsLink()}
            <Button sx={styles.button} onClick={handleClickFT}>Files</Button>
            <Button sx={styles.button} onClick={() => mailSupport()}>Support</Button>
            {renderADTLink()}
            <Button sx={styles.button} onClick={() => oliverSignOut()}>Sign Out</Button>
          </Toolbar>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link style={styles.link} to="/filetransfer"><MenuItem sx={styles.link} onClick={handleClose}>Transfer</MenuItem></Link>
            <Link style={styles.link} to="/ftdashboard"><MenuItem sx={styles.link} onClick={handleClose}>Dashboard</MenuItem></Link>
            {/* <Link style={styles.link} to="/missing"><MenuItem sx={styles.link} onClick={handleClose}>Missing</MenuItem></Link> */}
          </Menu>
          <SupersetMenu anchorEl={anchorElInsights} setReport={setReport} setAnchorElInsights={setAnchorElInsights} />
        </AppBar>
      );
    }
    return <></>;
  };

  const renderApp = ():JSX.Element => {
    if (token && type) {
      return (
        <UserContext.Provider value={{
          user, token, type, name, supersetUser, userName,
        }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className="App">
              <BrowserRouter>
                {renderToolBar()}
                <Routes>
                  {renderHomeRoute()}
                  <Route path="/adt" element={<Home />} />
                  {renderInsightsRoute()}
                  <Route path="filetransfer" element={<FileTransfer />} />
                  <Route path="matter/:matterUUID" element={<MatterHome />} />
                  <Route path="ftdashboard" element={<FileTransferDashboard />} />
                  <Route path="search" element={<SearchResults />}>
                    <Route path=":term" element={<SearchResults />} />
                  </Route>
                  {/* <Route path="missing" element={<MissingDocDashboard />} /> */}
                  <Route path="docusignClose" element={<Docusign />} />
                  <Route path="*" element={<UserDashboard />} />
                </Routes>
              </BrowserRouter>
              <IdleTimerModal signOut={() => oliverSignOut()} />
            </div>
          </LocalizationProvider>
        </UserContext.Provider>
      );
    }
    return <></>;
  };

  return (
    <ThemeProvider theme={theme}>
      {renderApp()}
    </ThemeProvider>
  );
};

export default Adt;
