import React, { useContext } from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle,
  DialogActions, Table, TableBody, TableRow, TableCell,
} from '@mui/material';
import UserContext from 'context/UserContext';
import Header from 'components/Header/Header';
import { ScrubModalProps } from './Scrub.types';
import { patchScrub } from './Scrub.api';

const ScrubModal:React.FunctionComponent<ScrubModalProps> = (props) => {
  const token = useContext(UserContext)?.token;
  const {
    open, handleClose, modalData,
  } = props;

  const renderExclusionItems = ():JSX.Element[] => modalData.exclusion_items.map((ei) => (
    <span key={ei}>
      {ei}
      <br />
    </span>
  ));

  const handleClick = async (action: string):Promise<void> => {
    const params = {
      action,
      scrub_uuid: modalData.scrub_uuid,
      matterID: modalData.matter_uuid,
      accountNumber: modalData.account_number,
      closedReasonCode: modalData.scrub_type,
      closedCommentsText: 'Scrub Approval',
    };

    const result = await patchScrub(token, params);
    if (result) {
      handleClose({}, 'button');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" disableEscapeKeyDown>
      <DialogTitle><Header variant="h3" text="Scrub Review" period /></DialogTitle>
      <DialogContent>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Matter Reference</TableCell>
              <TableCell>{modalData.external_account_id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Scrub Event</TableCell>
              <TableCell>{modalData.scrub_type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Scrub Date</TableCell>
              <TableCell>{modalData.generated_on}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Exclusion Items</TableCell>
              <TableCell>{renderExclusionItems()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>

        <Button variant="contained" color="primary" onClick={() => handleClose({}, 'button')}>Cancel</Button>
        <Button variant="contained" color="success" onClick={() => handleClick('approved')}>Approve</Button>
        <Button variant="contained" color="secondary" onClick={() => handleClick('rejected')}>Reject</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScrubModal;
