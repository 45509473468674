import React, {
  useState, useContext, useEffect,
} from 'react';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import { Action } from 'components/OliMaterialTable/OliMaterialTable.types';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import UserContext from 'context/UserContext';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Button, Typography,
} from '@mui/material';
import Header from 'components/Header/Header';
import { getConfig } from 'api/util/getConfig';
import OliAxios from 'api/util/OliAxios';
import {
  getList, postCustomer,
} from './Customer.api';
import {
  CustomerListData, DecryptedSSNHash,
} from './Customer.types';
import CustomerModal from './CustomerModal';
import { useStyles } from './Customer.jss';

const CustomerList: React.FunctionComponent = () => {
  const { matterUUID } = useParams();
  const token = useContext(UserContext)?.token;
  const [tableData, setTableData] = useState<CustomerListData[]>([]);
  const [loading, setLoading] = useState(false);

  const [modalData, setModalData] = useState<CustomerListData>();
  const [borrower, setBorrower] = useState<CustomerListData>();
  const [openModal, setOpenModal] = useState(false);
  const [spouseCheckType, setSpouseCheckType] = useState('');

  const [showDecryptedSSN, setShowDecryptedSSN] = useState('');
  const [decryptedSSN, setDecryptedSSN] = useState<DecryptedSSNHash>({});

  const classes = useStyles;

  const getListData = async ():Promise<void> => {
    setLoading(true);
    const data = await getList(token, matterUUID);
    if (data?.eventData) {
      setSpouseCheckType(data?.eventData);
    }
    if (data?.listData) {
      const formatteddata = data.listData.map((element) => ({
        ...element, date_of_birth: moment(element.date_of_birth).isValid() ? moment(element.date_of_birth).format('MM/DD/YYYY') : '',
      }));

      setTableData(formatteddata);
      setBorrower(data.listData.filter((element) => element.customer_type === 'Borrower' && element.official_state_prov_code === 'AZ')[0]);
    }
    setLoading(false);
  };

  const handleOpen = ():void => setOpenModal(true);
  const handleClose = (e:Record<string, unknown>, reason:string):void => {
    if (reason !== 'backdropClick') {
      setModalData(undefined);
      setOpenModal(false);
      getListData();
    }
  };

  const handleClick = (rowData:CustomerListData):void => {
    setModalData(rowData);
    handleOpen();
  };

  const spouseCheck = (check: string):void => {
    setModalData({
      ...modalData as CustomerListData, spouse: check, matter_uuid: matterUUID,
    });
  };

  const decryptSSN = async (data: CustomerListData):Promise<void> => {
    if (token && showDecryptedSSN === '') {
      const config = getConfig(token);
      const url = '/util/decrypt';
      const params = {
        decrypt: data?.ssn_ein_secure_two_way,
      };
      const result = await OliAxios.get(url, {
        ...config, params,
      });
      if (result && result.data) {
        setShowDecryptedSSN(data.ssn_ein_secure_two_way);
        setDecryptedSSN({
          ...decryptedSSN, [data.ssn_ein_secure_two_way]: result.data as string,
        });
        setTimeout(() => { setShowDecryptedSSN(''); }, 1500);
      }
    }
  };

  useEffect(() => {
    const postSpouse = async ():Promise<void> => {
      setLoading(true);
      await postCustomer(token, 'create', modalData as CustomerListData);
      getListData();
    };

    if (modalData?.spouse === 'VERIFIED_SPOUSE') {
      handleClick(modalData);
    }
    if (modalData?.spouse === 'UNABLE_TO_VERIFY_SPOUSE' || modalData?.spouse === 'POSSIBLE_SPOUSE') {
      postSpouse();
    }
  }, [modalData]);

  useEffect(() => {
    if (!token || !matterUUID) return;
    getListData();
  }, [token, matterUUID]);

  const columns = [
    {
      title: 'Name',
      field: 'entity_name',
      render: (rowData: CustomerListData) => (
        <Typography>
          {rowData.last_name}
          ,
          {' '}
          {rowData.first_name}
        </Typography>
      ),
    },
    {
      title: 'Client Customer Number Type', field: 'client_customer_number_type_code',
    },
    {
      title: 'Customer Type', field: 'customer_type',
    },
    {
      title: 'SSN',
      field: 'ssn_ein_last_four',
      render: (rowData: CustomerListData) => (
        <Typography onClick={() => decryptSSN(rowData)}>
          {showDecryptedSSN === rowData.ssn_ein_secure_two_way ? decryptedSSN[rowData.ssn_ein_secure_two_way] : rowData?.ssn_ein_last_four}
        </Typography>
      ),
    },
    {
      title: 'Date of Birth', field: 'date_of_birth',
    },
  ];

  const actions:Action[] = [
    {
      icon: () => <EditIcon />,
      tooltip: 'View Customer',
      onClick: (_e, rowData) => handleClick(rowData as CustomerListData),
    },
    {
      icon: () => <AddIcon />,
      tooltip: 'Add Customer',
      isFreeAction: true,
      onClick: (_e, rowData) => handleClick(rowData as CustomerListData),
    },
  ];

  const renderSpouseCheck = ():JSX.Element => {
    if (borrower?.official_state_prov_code === 'AZ') {
      return (
        <>
          <Header text="Spouse Check" period noMargin="bottom" />
          <Button variant="contained" color={spouseCheckType === 'VERIFIED_SPOUSE' ? 'secondary' : 'primary'} onClick={() => spouseCheck('VERIFIED_SPOUSE')} disabled={loading}>
            Verified Spouse
          </Button>
          <Button
            variant="contained"
            color={spouseCheckType === 'UNABLE_TO_VERIFY_SPOUSE' ? 'secondary' : 'primary'}
            sx={classes.spouseButton}
            onClick={() => spouseCheck('UNABLE_TO_VERIFY_SPOUSE')}
            disabled={loading}
          >
            Unable to Verify Spouse
          </Button>
          <Button variant="contained" color={spouseCheckType === 'POSSIBLE_SPOUSE' ? 'secondary' : 'primary'} onClick={() => spouseCheck('POSSIBLE_SPOUSE')} disabled={loading}>
            Possible Spouse
          </Button>
          <hr />
        </>
      );
    }
    return <></>;
  };

  const renderModal = ():JSX.Element => {
    if (modalData && modalData?.spouse !== 'UNABLE_TO_VERIFY_SPOUSE' && modalData?.spouse !== 'POSSIBLE_SPOUSE') {
      return (<CustomerModal open={openModal} handleClose={handleClose} modalData={modalData} />);
    }
    return <></>;
  };

  return (
    <div>
      {renderSpouseCheck()}
      {renderModal()}
      <OliMaterialTable
        title="Customer"
        actions={actions}
        columns={columns}
        loading={loading}
        data={tableData}
        pagesize={10}
        search
        selectable={false}
      />
    </div>
  );
};

export default CustomerList;
