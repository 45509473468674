import React, {
  useState, useContext,
} from 'react';
import {
  Drawer, List, ListItem, ListItemButton, ListItemText, Divider,
  Toolbar, Box,
} from '@mui/material';
import UserContext from 'context/UserContext';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import FileTransfer from 'components/FileTransfer/FileTransfer';
import ScrubList from 'components/UserDashboard/Widgets/Scrubs/ScubList';
import Cards from './Cards';
import AttorneyList from '../Attorney/AttorneyList';
import CustomerList from '../Customer/CustomerList';
import TransactionList from '../Transactions/TransactionList';
import AccountDetails from '../AccountDetails/AccountDetails';

const MatterHome: React.FunctionComponent = () => {
  const drawerWidth = 240;
  const tableloading = false;
  const [activePane, setActivePane] = useState('Documents');
  const token = useContext(UserContext)?.token;

  const columns = [
    {
      title: 'Matter Reference', field: 'matter_reference',
    },
    {
      title: 'Matter Creation Date', field: 'created_on',
    },
    {
      title: 'Missing Document Type', field: 'doc_type',
    },
  ];

  const tabledata = [
    {
      id: 123, matter_reference: 123, created_on: '123', doc_type: 'hehe',
    },
    {
      id: 13, matter_reference: 13, created_on: '123', doc_type: 'hehe',
    },
    {
      id: 23, matter_reference: 23, created_on: '123', doc_type: 'hehe',
    },
    {
      id: 1235, matter_reference: 1235, created_on: '123', doc_type: 'hehe',
    },
  ];
  const renderBottomPane = (): JSX.Element => {
    if (activePane === 'Customer') {
      return <CustomerList />;
    }
    if (activePane === 'Attorney') {
      return <AttorneyList />;
    }
    if (activePane === 'Documents') {
      return <FileTransfer />;
    }
    if (activePane === 'Scrubs') {
      return <ScrubList />;
    }
    if (activePane === 'Transactions') {
      return <TransactionList />;
    }
    if (activePane === 'Account Details') {
      return <AccountDetails />;
    }
    return <OliMaterialTable title={activePane} columns={columns} data={tabledata} pagesize={5} loading={tableloading} />;
  };
  const renderHome = (): JSX.Element => {
    if (!token) {
      return <div />;
    }
    return (
      <>
        <Box sx={{
          display: 'flex', zIndex: -1,
        }}
        >
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar />
            <Divider />
            <List>
              {['Documents', 'Settlements', 'Audits', 'Transactions', 'Account Details', 'Customer', 'Attorney', 'Scrubs'].map((text) => (
                <span key={text}>
                  <ListItem key={text} disablePadding onClick={() => setActivePane(text)}>
                    <ListItemButton selected={text === activePane}>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </span>
              ))}
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1, bgcolor: '#f2f2f2', p: 3, minHeight: '100vh',
            }}
          >
            <Cards />
            <br />
            <Divider />
            <br />
            {renderBottomPane()}
          </Box>
        </Box>
      </>
    );
  };

  return (<>{renderHome()}</>);
};
export default MatterHome;
