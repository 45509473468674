import React from 'react';
import {
  Grid, Button, Dialog, DialogContent, DialogTitle, DialogActions, TextField, InputLabel, Select, MenuItem, Alert, SelectChangeEvent, FormControl,
} from '@mui/material';
import { MatterDialogProps } from './Matter.types';
import { useStyles } from './Cards.jss';

const MatterDialog: React.FunctionComponent<MatterDialogProps> = (props) => {
  const {
    matterClose, isSuccess, reason,
    cancelCloseMatter, saveCloseMatter, onChangeReason, onChangeNotes, notes, openFail, errorMessage,
  } = props;
  const onChangeReas = (event: SelectChangeEvent): void => {
    onChangeReason(event.target.value);
  };

  return (
    <>
      <Dialog open={matterClose} fullWidth maxWidth="lg" disableEscapeKeyDown onClose={cancelCloseMatter}>
        <DialogContent dividers>
          <DialogTitle>
            Close a matter
            {isSuccess ? (
              <Grid item xs={12}>
                <Alert severity="success">Close Matter data saved succesfully.</Alert>
              </Grid>
            ) : null}
            {openFail ? (
              <Grid item xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            ) : null}
          </DialogTitle>
          <br />
          <FormControl variant="standard" sx={useStyles.formControl}>
            <InputLabel id="tag-select-label">Choose a Reason</InputLabel>
            <Select
              labelId="close-matter"
              id="close-matter-id-key"
              label="Reason"
              fullWidth
              onChange={onChangeReas}
              value={reason}
            >
              <MenuItem value="CASE_CLOSED">Case Closed</MenuItem>
              <MenuItem value="MIN_CRITERIA_NOT_MET">Minimum Criteria Not Met</MenuItem>
              <MenuItem value="PAID_IN_FULL">Case is Settled in Full</MenuItem>
            </Select>
          </FormControl>
          <br />
          <TextField
            label="Close Notes"
            fullWidth
            size="medium"
            required
            multiline
            rows={10}
            value={notes}
            onChange={(e) => onChangeNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={cancelCloseMatter}>Cancel</Button>
          <Button variant="contained" color="error" onClick={saveCloseMatter}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MatterDialog;
