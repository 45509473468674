import {
  Paper,
} from '@mui/material';
import React from 'react';
import MaterialTable from '@material-table/core';
import {
  IMaterialTableProps,
} from './OliMaterialTable.types';

const OliMaterialTable: React.FunctionComponent<IMaterialTableProps> = (props) => {
  const {
    data, columns, actions, loading, title, pagesize, search, selectable, filter, tableRef, header,
  } = props;
  const columnStyle = { headerStyle: { fontWeight: 'bold' } };

  const showSelection = actions && actions?.length > 0 && selectable !== false;
  const tableTitle = title || '';
  const tablePageSize = pagesize || 20;

  const options = {
    selection: showSelection, search: search || false, pageSize: tablePageSize, ...columnStyle, filtering: filter || false, header: header === undefined,
  };

  return (
    <>
      <Paper sx={{
        overflow: 'hidden',
      }}
      >
        <MaterialTable
          tableRef={tableRef}
          title={tableTitle}
          columns={columns}
          data={data}
          options={options}
          actions={actions}
          isLoading={loading}
        />
      </Paper>
    </>
  );
};

export default OliMaterialTable;
