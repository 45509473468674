import React, { useContext } from 'react';
import UserContext from 'context/UserContext';
import {
  Container, Grid,
} from '@mui/material';
import Header from 'components/Header/Header';
import useUser from 'hooks/useUser.hook';
import InterviewList from './Widgets/Interviews/InterviewList';
import ScrubList from './Widgets/Scrubs/ScubList';
import SignatureReviewList from './Widgets/SignatureReview/SignatureReviewList';

const UserDashboard: React.FunctionComponent = () => {
  let userName = useContext(UserContext)?.userName;
  const { email } = useUser(useContext(UserContext)?.user);
  if ((userName === undefined || userName === '')) {
    userName = email?.split('@')[0]?.split('.')[0];
  }
  const jwttoken = useContext(UserContext)?.token;
  const renderDashboard = (): JSX.Element => {
    if (!jwttoken) {
      return <div />;
    }

    return (
      <Container maxWidth={false}>
        <Header text={`Welcome, ${userName}`} period />
        <Grid container direction="row" align-items="stretch" justifyContent="space-between" spacing={3}>
          <Grid item xs={6}><InterviewList /></Grid>
          <Grid item xs={6}><ScrubList /></Grid>
          <Grid item xs={12}><SignatureReviewList /></Grid>
        </Grid>
      </Container>
    );
  };
  return (<>{renderDashboard()}</>);
};

export default UserDashboard;
