import React, {
  useState, useContext, useEffect,
} from 'react';
import {
  Grid, Container, TextField, Button, CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Header from 'components/Header/Header';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import UserContext from 'context/UserContext';
import { useStyles } from './FileTransfer.jss';
import Dropzones from './Dropzones/Dropzones';
import List from './List/List';
import {
  Data, ErrorNotFound,
} from './FileTransfer.types';
import SmartUploader from './SmartUploader/SmartUploader';

const FileTransfer: React.FunctionComponent = () => {
  const [showUpDown, setShowUpDown] = useState(false);
  const [showNotFound, setShowNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderSmart, setRenderSmart] = useState(true);
  const [fileNo, setFileNo] = useState('');
  const [displayFileNo, setDisplayFileNo] = useState('');
  const classes = useStyles;
  const token = useContext(UserContext)?.token;
  const [data, setData] = useState([] as Data[]);
  const { matterUUID } = useParams();
  const simpleFileTransfer = !!matterUUID;

  const clear = ():void => {
    setFileNo('');
    setRenderSmart(true);
    setShowUpDown(false);
    setShowNotFound(false);
  };

  const handleSuccess = (result:Data[] | ErrorNotFound):void => {
    setLoading(false);
    if (result instanceof Array) {
      setData(result);
      setShowUpDown(true);
      setDisplayFileNo(fileNo);
    } else {
      setShowUpDown(false);
      setShowNotFound(true);
    }
  };

  const getFileData = async ():Promise<void> => {
    setShowUpDown(false);
    setShowNotFound(false);
    setLoading(true);
    setRenderSmart(false);
    if (token) {
      const config = getConfig(token);
      const url = '/fileTransfer/list';
      const params = {
        file_no: fileNo, matter_uuid: matterUUID,
      };
      await OliAxios.get(url, {
        ...config, params,
      }).then((result) => handleSuccess(result.data));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
    setFileNo(event.target.value);
  };

  const renderNotFound = ():JSX.Element => {
    if (showNotFound) {
      return (
        <>
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
            <Grid item>
              <Header text="Account Not Found" period />
            </Grid>
          </Grid>
        </>
      );
    }
    return <></>;
  };

  const renderUploadDownload = ():JSX.Element => {
    let header = <></>;
    if (!simpleFileTransfer) {
      header = (
        <>
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
            <Grid item>
              <Header text={displayFileNo} period />
            </Grid>
          </Grid>
          <hr />
        </>
      );
    }
    if (showUpDown) {
      return (
        <>
          {header}
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={6} sx={{ paddingRight: '1.9%' }}>
              <Header text="Download" period />
              <List data={data} fileNo={displayFileNo} getFileData={getFileData} />
            </Grid>
            <Grid item xs={6}>
              <Header text="Upload" period />
              <Dropzones fileNo={displayFileNo} matterUuid={matterUUID} getFileData={getFileData} />
            </Grid>
          </Grid>
        </>
      );
    }
    return <></>;
  };

  const renderLoading = ():JSX.Element => {
    if (loading) {
      return (
        <>
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
            <Grid item>
              <br />
              <CircularProgress />
            </Grid>
          </Grid>
        </>
      );
    }
    return <></>;
  };
  const renderSmartUploader = ():JSX.Element => {
    if (renderSmart && !simpleFileTransfer) {
      return <SmartUploader />;
    }
    return <></>;
  };

  const renderSearch = ():JSX.Element => {
    if (!simpleFileTransfer) {
      return (
        <>
          <Header text="File Transfer" period />
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={10}>
              <TextField label="File Number / FILENO" variant="outlined" size="small" fullWidth sx={classes.textField} onChange={handleChange} value={fileNo} />
            </Grid>
            <Grid item xs={2}>
              <Button color="secondary" variant="contained" component="span" onClick={() => getFileData()} disabled={loading}>
                Search
              </Button>
              &nbsp;
              <Button color="primary" variant="contained" component="span" onClick={() => clear()} disabled={loading}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (token && simpleFileTransfer) {
      getFileData();
    }
  }, [simpleFileTransfer, token]);

  return (
    <>
      <Container maxWidth="xl">
        {renderSearch()}
        {renderLoading()}
        {renderNotFound()}
        {renderUploadDownload()}
        {renderSmartUploader()}
      </Container>
    </>
  );
};

export default FileTransfer;
