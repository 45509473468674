import React, {
  useContext, useState,
} from 'react';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import {
  Action, MTQuery,
} from 'components/OliMaterialTable/OliMaterialTable.types';
import EditIcon from '@mui/icons-material/Edit';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import UserContext from 'context/UserContext';
import {
  MaterialTableProps, Query,
} from '@material-table/core';
import { TemplateGeneratedArtifactData } from './Interview.types';
import InterviewModal from './InterviewModal';

const InterviewList:React.FunctionComponent = () => {
  const tableRef = React.useRef();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<TemplateGeneratedArtifactData>();
  const token = useContext(UserContext)?.token;
  const handleOpen = ():void => setOpenModal(true);

  // eslint-disable-next-line
  const constructUrl = (query:Query<object>): string => {
    const qsp: string[] = [];
    if (query.filters) {
      query.filters.forEach((f) => {
        qsp.push(`${f.column.field as string}=${f.value as string}`);
      });
    }

    qsp.push(`per_page=${query.pageSize}`);
    qsp.push(`page=${query.page + 1}`);

    if (query.orderBy && query.orderDirection) {
      qsp.push(`order=${query.orderBy.field as string}`);
      qsp.push(`orderDirection=${query.orderDirection}`);
    }

    const queryStringParams = qsp.join('&');
    if (queryStringParams.length > 0) {
      return `?${queryStringParams}`;
    }
    return '';
  };

  const getList = ():void => {
    if (tableRef && tableRef.current) {
      (tableRef.current as MaterialTableProps<Record<string, unknown>>)?.onQueryChange?.();
    }
  };

  const handleClose = (e:Record<string, unknown>, reason:string):void => {
    if (reason !== 'backdropClick') {
      setOpenModal(false);
      getList();
      setModalData(undefined);
    }
  };
  const columns = [
    {
      title: 'Matter Reference', field: 'matter_reference',
    },
    {
      title: 'Creation Date', field: 'created_on',
    },
    {
      title: 'Firm Name', field: 'firm_name',
    },
    {
      title: 'Oliver Description', field: 'template_reference',
    },
  ];

  const actions:Action[] = [{
    icon: () => <EditIcon />,
    tooltip: 'View',
    onClick: (_e, rowData) => handleClick(rowData as TemplateGeneratedArtifactData), //eslint-disable-line
  }];

  const handleClick = (rowData:TemplateGeneratedArtifactData):void => {
    setModalData(rowData);
    handleOpen();
  };

  const renderModal = ():JSX.Element => {
    if (modalData) {
      return (<InterviewModal open={openModal} handleClose={handleClose} modalData={modalData} />);
    }
    return <></>;
  };

  return (
    <div>
      {renderModal()}
      <OliMaterialTable
        tableRef={tableRef}
        title="Interviews"
        columns={columns}
        data={(query) => new Promise((resolve) => {
          const url = `/interview/list${constructUrl(query)}`;
          OliAxios.get(url, { ...getConfig(token || '') })
            .then((response) => response.data as unknown as MTQuery)
            .then((result) => {
              resolve({
                data: result.data,
                page: parseInt(result.page, 10) - 1,
                totalCount: parseInt(result.total, 10),
              });
            })
            .catch((e) => console.error(e));
        })}
        pagesize={5}
        actions={actions}
        selectable={false}
        filter
      />
    </div>
  );
};

export default InterviewList;
