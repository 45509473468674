import React, {
  useState, useContext, useEffect,
} from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions, Box, SelectChangeEvent,
  Accordion, AccordionSummary, AccordionDetails, InputLabel, Select, MenuItem, FormControl,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OliTextField from 'components/Shared/OliTextField/OliTextField';
import OliDateField from 'components/Shared/OliDateField/OliDateField';
import UserContext from 'context/UserContext';
import StateSelect from 'components/Shared/StateSelect/StateSelect';
import Header from 'components/Header/Header';
import { Moment } from 'moment';
import { useParams } from 'react-router-dom';
import {
  CustomerModalProps, CustomerListData,
} from './Customer.types';
import { postCustomer } from './Customer.api';
import { useStyles } from './Customer.jss';

// eslint-disable-next-line max-lines-per-function
const CustomerModal:React.FunctionComponent<CustomerModalProps> = (props) => {
  const { matterUUID } = useParams();
  const token = useContext(UserContext)?.token;
  const {
    open, handleClose, modalData,
  } = props;

  const setupFormData = ():Record<string, unknown> => {
    const formdata:Record<string, unknown> = {};
    Object.keys(modalData as CustomerListData).forEach((key) => {
      formdata[key] = (modalData as CustomerListData)[key as keyof CustomerListData];
    });
    return formdata;
  };

  const [formData, setFormData] = useState<Record<string, unknown>>(setupFormData());
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setupFormData();
  }, [modalData]);

  const handleFieldChange = (event:React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>, key: string):void => {
    setFormData({
      ...formData, [key]: event.target.value,
    });
  };

  const handleDateFieldChange = (event:Moment | null, key: string):void => {
    setFormData({
      ...formData, [key]: event?.format('YYYY-MM-DD'),
    });
  };

  const handleSubmitClick = async ():Promise<void> => {
    if (!token) return;
    setLoading(true);
    const action = formData?.customer_uuid ? 'update' : 'create';
    formData.matter_uuid = formData.matter_uuid !== undefined ? formData.matter_uuid : matterUUID;
    await postCustomer(token, action, formData);
    handleClose({}, 'button');
    setLoading(false);
  };

  const isValid = ():boolean => {
    let valid = true;
    const required = ['first_name', 'last_name', 'customer_type'];
    required.forEach((r) => {
      if (!formData[r]) {
        valid = false;
      }
    });

    return valid;
  };

  const classes = useStyles;

  const renderCustomerType = ():JSX.Element => {
    if (!formData.customer_uuid) {
      return (
        <FormControl variant="standard" sx={classes.formControl}>
          <InputLabel id="select-label" error={!formData.customer_type} required>Customer Type</InputLabel>
          <Select
            labelId="debtor"
            id="demo-simple-select"
            value={formData.customer_type || ''}
            label="Debtor"
            variant="standard"
            disabled={loading}
            required
            error={!formData.customer_type}
            onChange={(event) => handleFieldChange(event as SelectChangeEvent<string>, 'customer_type')}
          >
            <MenuItem value="Coborrower">Co-borrower</MenuItem>
            <MenuItem value="NonBorrowingDefendant">Non-Borrowing Defendant</MenuItem>
          </Select>
        </FormControl>
      );
    }
    return <></>;
  };

  const renderFields = ():JSX.Element[] | undefined => {
    const fields = [{
      label: 'First Name', value: formData.first_name, type: 'text', event: 'first_name', required: true,
    }, {
      label: 'Last Name', value: formData.last_name, type: 'text', event: 'last_name', required: true,
    }, {
      label: 'Entity Name', value: formData.entity_name, type: 'text', event: 'entity_name', required: false,
    }, {
      label: 'Salutation', value: formData.salutation, type: 'text', event: 'salutation', required: false,
    }, {
      label: 'Date of Birth', value: formData.date_of_birth, type: 'date', event: 'date_of_birth', required: false,
    }, {
      label: 'Also Known As', value: formData.also_known, type: 'text', event: 'also_known', required: false,
    }, {
      label: 'Client Customer Number Type Code', value: formData.client_customer_number_type_code, type: 'text', event: 'client_customer_number_type_code', required: false,
    }, {
      label: 'Phone Number', value: formData.phone, type: 'text', event: 'phone', required: false,
    }, {
      label: 'Email', value: formData.email, type: 'text', event: 'email', required: false,
    }, {
      label: 'Language Preference', value: formData.language_preference, type: 'text', event: 'language_preference', required: false,
    }, {
      label: 'NY Language Preference', value: formData.language_preference_ny, type: 'text', event: 'language_preference_ny', required: false,
    }];

    return fields.map((f) => {
      if (f.type === 'text') {
        return (
          <OliTextField
            key={f.label}
            value={f.value as string}
            label={f.label}
            handleChange={(event) => handleFieldChange(event, f.event)}
            disabled={loading}
            required={f.required}
            error={!formData[f.event] && f.required}
          />
        );
      }
      if (f.type === 'date') {
        return (
          <OliDateField
            key={f.label}
            value={f.value as string}
            label={f.label}
            handleChange={(event) => handleDateFieldChange(event, f.event)}
            disabled={loading}
          />
        );
      }

      return <></>;
    });
  };

  const renderAddressBlock = (type:string, prefix:string):JSX.Element => (
    <>
      <br />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={classes.accordion}>
          <Header variant="h3" text={type} short noMargin="bottom" period />
        </AccordionSummary>
        <AccordionDetails>
          <Box alignItems="flex-end" component="form" noValidate sx={classes.box}>
            <OliTextField
              value={formData[`${prefix}_address_line_1`] as string}
              label="Address 1"
              handleChange={(event) => handleFieldChange(event, `${prefix}_address_line_1`)}
              disabled={loading || prefix === 'official'}
            />
            <OliTextField
              value={formData[`${prefix}_address_line_2`] as string}
              label="Address 2"
              handleChange={(event) => handleFieldChange(event, `${prefix}_address_line_2`)}
              disabled={loading || prefix === 'official'}
            />
            <OliTextField
              value={formData[`${prefix}_city`] as string}
              label="City"
              handleChange={(event) => handleFieldChange(event, `${prefix}_city`)}
              disabled={loading || prefix === 'official'}
            />
            <OliTextField
              value={formData[`${prefix}_country`] as string}
              label="Country"
              handleChange={(event) => handleFieldChange(event, `${prefix}_country`)}
              disabled={loading || prefix === 'official'}
            />
            <OliTextField
              value={formData[`${prefix}_postal_code`] as string}
              label="Postal Code"
              handleChange={(event) => handleFieldChange(event, `${prefix}_postal_code`)}
              disabled={loading || prefix === 'official'}
            />
            <StateSelect
              label="State"
              value={formData[`${prefix}_state_prov_code`] as string}
              loading={loading || prefix === 'official'}
              handleChange={(event) => handleFieldChange(event, `${prefix}_state_prov_code`)}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <br />
    </>
  );

  const renderModal = ():JSX.Element => {
    if (modalData) {
      return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
          <DialogTitle>
            {formData.customer_type as string || 'Add New Customer'}
          </DialogTitle>
          <DialogContent>
            <Box alignItems="flex-end" component="form" noValidate sx={classes.box}>
              { renderFields() }
              { renderCustomerType() }
            </Box>
            { renderAddressBlock('Official Address', 'official') }
            { renderAddressBlock('Physical Address', 'physical') }
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" disabled={loading} onClick={() => handleClose({}, 'button')}>Cancel</Button>
            <Button variant="contained" color="primary" disabled={loading || !isValid()} onClick={handleSubmitClick}>Submit</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return <></>;
  };

  return (
    <>
      { renderModal() }
    </>
  );
};

export default CustomerModal;
