import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Button, DialogContent, DialogTitle, DialogActions,
} from '@mui/material';
import UserContext from 'context/UserContext';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import { DocusignProps } from './Docusign.types';

const Docusign:React.FunctionComponent<DocusignProps> = (props) => {
  const token = useContext(UserContext)?.token;
  const {
    handleClose, modalData,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [iframeUrl, setIframeUrl] = useState<string>('');

  type DocusignResponse = {
    data: DocusignResponseData
  };
  type DocusignResponseData = {
    url: string
  };

  const grabData = async ():Promise<void> => {
    setLoading(true);
    const url = '/docusignIntegration';
    if (!token) return;
    const response:DocusignResponse = await OliAxios.post(url, { template_id: modalData?.id }, {
      ...getConfig(token),
    });
    console.info(response);
    setIframeUrl(response.data.url);
    setLoading(false);
  };

  useEffect(() => {
    if (!token) return;
    grabData();
  }, [token]);

  const renderIframe = ():JSX.Element => {
    if (loading || iframeUrl === '') {
      return <></>;
    }
    return (
      <iframe
        src={iframeUrl}
        title="docusign"
        style={{
          width: '100%', minHeight: '750px',
        }}
      />
    );
  };

  return (
    <>
      <DialogTitle>Docusign</DialogTitle>
      <DialogContent>
        {renderIframe()}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={() => handleClose({}, 'button')}>Cancel</Button>
      </DialogActions>
    </>
  );
};

export default Docusign;
