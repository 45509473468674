import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import {
  ParseSmartParams, ParseSmartResponse, PreSignedURLResponse,
} from '../FileTransfer.types';

const parseSmart = async (token: string | undefined, params: ParseSmartParams, retryCount = 0):Promise<ParseSmartResponse | undefined> => {
  if (params.filenames.length === 0) return undefined;
  if (token) {
    let retry = retryCount;
    const config = getConfig(token);
    const url = '/fileTransfer/parseSmart';
    try {
      return await OliAxios.get(url, {
        ...config, params,
      });
    } catch (e) {
      if (retry < 2) {
        parseSmart(token, params, retry += 1);
      }
    }
  }
  return undefined;
};

async function uploadFiles(token: string | undefined, file:File, filenames:string[], uploadTag:string, fileNo: string, description: string, retryCount = 0):Promise<void> {
  if (!token) return;
  let retry = retryCount;
  try {
    const response:PreSignedURLResponse = await OliAxios.get('/fileTransfer/upload', {
      ...getConfig(token),
      params: {
        filenames, tags: uploadTag, file_no: fileNo, description,
      },
    });
    const uploadUrls = response.data.uploadurls;
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    await OliAxios.put(uploadUrls[0].uploadURL, file, options);
  } catch (e) {
    if (retry < 2) {
      uploadFiles(token, file, filenames, uploadTag, fileNo, description, retry += 1);
    }
  }
}

export {
  parseSmart, uploadFiles,
};
