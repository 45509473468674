import React, {
  useState, useContext,
} from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions, Box,
} from '@mui/material';
import OliTextField from 'components/Shared/OliTextField/OliTextField';
import OliDateField from 'components/Shared/OliDateField/OliDateField';
import moment, { Moment } from 'moment';
import UserContext from 'context/UserContext';
import { InterviewModalProps } from './Interview.types';
import {
  mergePDF, approvePDF, rejectPDF, deleteInterview,
} from './InterviewModal.api';
import Docusign from './Docusign';

const InterviewModal:React.FunctionComponent<InterviewModalProps> = (props) => {
  const token = useContext(UserContext)?.token;
  const {
    open, handleClose, modalData,
  } = props;

  const setupFormData = ():Record<string, unknown> => {
    const formdata:Record<string, unknown> = {};
    modalData?.data_fields.forEach((df) => {
      formdata[df.key] = modalData.original_data[df.key] || df.defaultvalue;
    });
    return formdata;
  };

  const [formData, setFormData] = useState<Record<string, unknown>>(setupFormData());
  const [loading, setLoading] = useState<boolean>(false);
  const [pdfURL, setPdfURL] = useState<string>();
  const [s3key, setS3key] = useState<string>();
  const [showDocusign, setShowDocusign] = useState<boolean>(false);

  const handleTextFieldChange = (event:React.ChangeEvent<HTMLInputElement>, key: string):void => {
    setFormData({
      ...formData, [key]: event.target.value,
    });
  };

  const handleDateFieldChange = (event:Moment | null, key: string):void => {
    setFormData({
      ...formData, [key]: event?.format('YYYY-MM-DD'),
    });
  };

  const isValid = ():boolean => {
    let valid = true;
    modalData?.data_fields.forEach((df) => {
      if (df.required && (formData[df.key] === '' || !formData[df.key])) {
        valid = false;
      }
    });
    return valid;
  };

  const renderFields = ():JSX.Element[] => {
    if (!modalData) return [<></>];
    return modalData.data_fields.map((df) => {
      if (df.type === 'string') {
        return (
          <React.Fragment key={df.key}>
            <OliTextField
              value={formData[df.key] as string}
              label={df.label}
              required={df.required}
              error={!formData[df.key] && df.required}
              handleChange={(event) => handleTextFieldChange(event, df.key)}
              disabled={loading}
            />
          </React.Fragment>
        );
      }
      if (df.type === 'date') {
        return (
          <React.Fragment key={df.key}>
            <OliDateField
              value={formData[df.key] as string}
              label={df.label}
              handleChange={(event) => handleDateFieldChange(event, df.key)}
              disabled={loading}
              error={!formData[df.key] && df.required}
              required={df.required}
            />
          </React.Fragment>
        );
      }
      return <React.Fragment key={df.key} />;
    });
  };

  const formatFormDataDates = ():void => {
    modalData?.data_fields.forEach((df) => {
      if (df.type === 'date') {
        formData[df.key] = moment(formData[df.key] as string).format('MM/DD/YYYY');
      }
    });
  };

  const deleteClicked = async ():Promise<void> => {
    setLoading(true);
    const params = {
      id: modalData?.id,
    };
    const result = await deleteInterview(token, params);
    if (result) {
      handleClose({}, 'button');
    }
    setLoading(false);
  };

  const mergeClicked = async ():Promise<void> => {
    setLoading(true);
    formatFormDataDates();

    const params = {
      templateDocx: modalData?.template_reference,
      mergedData: formData,
      outputPdf: modalData?.s3_key,
    };
    const result = await mergePDF(token, params);
    if (result) {
      setPdfURL(result.data.downloadURL);
      setS3key(result.data.s3key);
    }
    setLoading(false);
  };

  const approvedClicked = async ():Promise<void> => {
    setLoading(true);
    if (modalData?.esign_required) {
      setShowDocusign(true);
    } else {
      const params = {
        s3key,
        matterUuid: modalData?.matter_uuid,
        tags: modalData?.client_tags,
        oliver_tag: modalData?.oliver_tag,
      };
      await approvePDF(token, params);
      handleClose({}, 'button');
    }
  };

  const rejectClicked = async ():Promise<void> => {
    setLoading(true);
    const params = {
      s3key,
    };
    const result = await rejectPDF(token, params);
    if (result) {
      setPdfURL('');
    }
    setLoading(false);
  };

  const renderMergeContent = ():JSX.Element => {
    if (!pdfURL) {
      return (
        <>
          <DialogTitle>{modalData?.template_reference}</DialogTitle>
          <DialogContent>
            <br />
            <Box
              alignItems="flex-end"
              component="form"
              noValidate
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  sm: '2fr 2fr',
                },
                gap: 2,
              }}
            >
              {renderFields()}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" disabled={loading} onClick={() => deleteClicked()}>Delete</Button>
            <Button variant="contained" color="primary" disabled={loading} onClick={() => handleClose({}, 'button')}>Cancel</Button>
            <Button variant="contained" color="primary" disabled={!isValid() || loading} onClick={mergeClicked}>Merge</Button>
          </DialogActions>
        </>
      );
    }
    return <></>;
  };

  const renderApprovalContent = ():JSX.Element => {
    if (pdfURL && !showDocusign) {
      return (
        <>
          <DialogTitle>Approve or Reject</DialogTitle>
          <DialogContent>
            <iframe
              style={{
                width: '100%', minHeight: '750px',
              }}
              title="preview"
              src={pdfURL}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" disabled={loading} onClick={rejectClicked}>Reject</Button>
            <Button variant="contained" color="primary" disabled={loading} onClick={approvedClicked}>Approve</Button>
          </DialogActions>
        </>
      );
    }
    return <></>;
  };

  const renderDocusign = ():JSX.Element => {
    if (showDocusign) {
      return <Docusign modalData={modalData} handleClose={handleClose} />;
    }
    return <></>;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
      {renderMergeContent()}
      {renderApprovalContent()}
      {renderDocusign()}
    </Dialog>
  );
};

export default InterviewModal;
